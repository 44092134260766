import React from 'react';
import Container from '@components/Container';

import styles from './styles.module.css';
import data from './data.json';

export default () => (
  <div className={styles.container}>
    <Container>
      <b className={styles.title}>{data.title}</b>
      <ul className={styles.list}>
        {data.items.map((text, index) => (
          <li key={index} className={styles.item}>
            {text}
          </li>
        ))}
      </ul>
    </Container>
  </div>
);
