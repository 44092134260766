import React from 'react';
import MediaQuery from 'react-responsive';

import styles from './styles.module.css';
import data from '../data.js';

export default function({ index, title, text, Icon }) {
  const step = index + 1;
  const highlight = `${data.subtitle} ${step}. `;

  return (
    <div className={styles.container}>
      <b className={styles.subtitle}>
        <span className={styles.highlight}>{highlight}</span>
        {title}
      </b>
      <p className={styles.text}>{text}</p>
      <MediaQuery minWidth={768}>
        <Icon className={styles.icon} height={90} width={90} />
      </MediaQuery>
    </div>
  );
}
