import React from 'react';
import Body from '@sections/About';
import Layout from '@layouts/business';
import SEO from '@components/seo';

export default () => (
  <Layout>
    <SEO
      title="О нас | IMstream"
      description="IMstream – профессиональный сервис по предоставлению музыки для бизнеса. Мы предлагаем нашим клиентам возможность музыкального оформления заведений «под ключ»."
    />
    <Body />
  </Layout>
);
