import React, { Fragment } from 'react';
import Intro from './Intro';
import Offer from './Offer';
import Benefits from './Benefits';
import WorkProccess from './WorkProcess';

import SeparationLink from '@components/SeparationLink';
import CTA from '@components/CallToAction';

export default function() {
  return (
    <Fragment>
      <Intro />
      <Offer />
      <SeparationLink />
      <Benefits />
      <WorkProccess />
      <CTA />
    </Fragment>
  );
}
