import React from 'react';

import Container from '@components/Container';

import data from './data.json';
import styles from './styles.module.css';

export default () => (
  <section className={styles.container}>
    <Container>
      <b className={styles.subtitle}>{data.subtitle}</b>
      <ul className={styles.list}>
        {data.items.map((item, index) => (
          <li
            key={index}
            className={styles.item}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </ul>
    </Container>
  </section>
);
