import React from 'react';
import { Link } from 'gatsby';
import { br } from '@utils';

import { legal } from '@data/navigation.json';
import { text } from './data.json';
import styles from './styles.module.css';

export default function() {
  return (
    <div className={styles.container}>
      <Link className={styles.link} to={legal.href}>
        {br(text)}
      </Link>
    </div>
  );
}
