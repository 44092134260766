import React from 'react';
import Container from '@components/Container';

import Card from './Card';

import data from './data.js';
import styles from './styles.module.css';

export default () => (
  <div className={styles.container}>
    <Container>
      <h2 className={styles.title}>{data.title}</h2>
      <div className={styles.wrapper}>
        {data.items.map((item, index) => (
          <Card key={item.title} {...item} index={index} />
        ))}
      </div>
    </Container>
  </div>
);
