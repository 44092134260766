import React from 'react';
import Container from '@components/Container';
import styles from './styles.module.css';
import data from './data.json';

export default function() {
  return (
    <div className={styles.container}>
      <Container>
        <b className={styles.title}>{data.title}</b>
        <ul className={styles.list}>
          {data.items.map((text, index) => (
            <li className={styles.item} key={index}>
              {text}
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
}
