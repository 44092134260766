import { Speech1, Target1, Handshake1, Document1 } from '@icons';

export default {
  title: 'Как мы работаем',
  subtitle: 'Шаг',
  items: [
    {
      title: 'Обсуждение',
      Icon: Speech1,
      text:
        'Получаем от вас как можно больше информации относительно того, где, когда и как будет использоваться плейлист. Выносим на обсуждение, проговариваем варианты возможной музыки, отбрасываем то, что не подходит и, наконец, принимаем решение.',
    },
    {
      title: 'Выбор концепции',
      Icon: Target1,
      text:
        'Музыка управляет эмоциями ваших клиентов и во многих сферах бизнеса способствует увеличению продаж. При принятии решения о том, каким должен быть музыкальный фон вашего заведения, мы всегда держим в голове эту простую мысль и подбираем такую музыку, которая будет отвечать намеченным целям.',
    },
    {
      title: 'Согласование',
      Icon: Handshake1,
      text:
        'Коммуникация - ключ к успешному сотрудничеству. Мы принимаем ваши замечания и всевозможные пожелания, если таковые возникнут, и на выходе получаем полностью подходящий под ваши задачи плейлист.',
    },
    {
      title: 'Заключение договора',
      Icon: Document1,
      text:
        'Отправляем на подпись договор, после подписания которого вы получаете неограниченный доступ ко всем нашим музыкальным композициям, а также готовым плейлистам. Помимо этого, мы также ежемеcячно создаем новый плейлист по вашей заявке.',
    },
  ],
};
